import { BannerMessage, Loading, Partner, SitewideBanner, User } from './reducers';

// Redux Action Names
export const SET_USER = 'SET_USER';
export const SET_LOADING = 'SET_LOADING';
export const SET_PARTNER = 'SET_PARTNER';
export const SET_BANNER_MESSAGE = 'SET_BANNER_MESSAGE';
export const SET_SITEWIDE_BANNER = 'SET_SITEWIDE_BANNER';

export interface SetUserAction {
	type: typeof SET_USER;
	payload: User;
}
export type UserActionTypes = SetUserAction;

export interface SetLoadingAction {
	type: typeof SET_LOADING;
	payload: Loading;
}

export interface SetPartnerAction {
	type: typeof SET_PARTNER;
	payload: Partner;
}

// Banner Message
export interface SetBannerMessageAction {
	type: typeof SET_BANNER_MESSAGE;
	payload: BannerMessage;
}

// Sitewide Banner
export interface SetSitewideBannerAction {
	type: typeof SET_SITEWIDE_BANNER;
	payload: SitewideBanner;
}

export type RecaptchaVisible = boolean;

export const SET_RECAPTCHA_VISIBLE = 'SET_RECAPTCHA_VISIBLE';

export interface SetRecaptchaVisibleAction {
	type: typeof SET_RECAPTCHA_VISIBLE;
	payload: RecaptchaVisible;
}

export type SetRecaptchaVisibleTypes = SetRecaptchaVisibleAction;
