import { AnyAction } from 'redux';

import { RecaptchaVisible, SET_RECAPTCHA_VISIBLE } from '../../types/store/reducers';

const initialState: RecaptchaVisible = false;

export function recaptchaVisibleReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): RecaptchaVisible {
	switch (action.type) {
		case SET_RECAPTCHA_VISIBLE:
			return action.payload || initialState;
		default:
			return state;
	}
}
