import { PaletteColor } from '@calm-web/design-system';

import { JSONValue } from '@/types/json';
import { AccessPolicy } from '@/utils/RBAC';
import { ProductSKUDatabaseType, SKU } from '@/utils/SkuEnums';

type DefinedUser = {
	accessToken: string;
	idToken: string;
	accessPolicy: AccessPolicy;
	expiresIn: number;
} & Partial<{
	email: string;
	sub: string;
	name: string;
	given_name: string;
}>;

type EmptyUser = Partial<Record<keyof DefinedUser, undefined>>;

export type User = DefinedUser | EmptyUser;

export type Loading = boolean;

export enum IntegrationType {
	ACCESS_CODES = 'ACCESS_CODES',
	API = 'API',
	ELIGIBILITY_FILE = 'ELIGIBILITY_FILE',
	SSO = 'SSO',
	GROUP_CODE = 'GROUP_CODE',
	DTC_CODELESS = 'DTC_CODELESS',
}

export enum PartnerCategoryType {
	CALM_FOR_BUSINESS = 'CALM_FOR_BUSINESS',
	SALES_TRIAL = 'SALES_TRIAL',
	TELCO_PARTNERSHIP = 'TELCO_PARTNERSHIP',
	BRAND_PARTNERSHIP = 'BRAND_PARTNERSHIP',
	HEALTH_CARE_PROVIDER = 'HEALTH_CARE_PROVIDER',
	D2C_LEAD_GEN_PARTNERSHIP = 'D2C_LEAD_GEN_PARTNERSHIP',
	CALM_HEALTH = 'CALM_HEALTH',
}

export enum StripeCouponDuration {
	ONCE = 'once',
	REPEATING = 'repeating',
	FOREVER = 'forever',
}

export enum TrialDuration {
	NULL = 'null',
	SEVEN_DAYS = 'P7D',
	FOURTEEN_DAYS = 'P14D',
	ONE_MONTH = 'P1M',
	TWO_MONTHS = 'P2M',
	THREE_MONTHS = 'P3M',
	FOUR_MONTHS = 'P4M',
	SIX_MONTHS = 'P6M',
	TWELVE_MONTHS = 'P12M',
}

export enum Languages {
	SPANISH = 'es',
	FRENCH = 'fr',
}

export interface PartnerLinks {
	self: string;
	account?: string;
}

export interface ParentPartner {
	id: string;
	name: string;
}

export interface Partner {
	id: string;
	name: string;
	slug: string;
	logo_url?: string;
	dtc_logo_url?: string;
	client_id: string;
	is_sales_trial: boolean;
	is_hipaa_compliant: boolean;
	is_ldu: boolean;
	email_config_can_send_promo: boolean;
	email_config_can_send_upsell: boolean;
	email_config_can_send_download_app: boolean;
	email_config_is_branded: boolean;
	email_config_can_send_benefit_reminders: boolean;
	email_config_can_send_survey: boolean;
	supports_eligibility_list: boolean;
	user_id_descriptor?: string;
	integration_type: IntegrationType;
	vouched_plan_sku: SKU;
	contract_starts_at: string;
	contract_expires_at: string;
	contract_covered_lives: number;
	max_dependents_per_user: number;
	update_plan_payment: boolean;
	web_renew_url: string;
	ios_renew_url: string;
	android_renew_url: string;
	category: PartnerCategoryType;
	product_sku: ProductSKUDatabaseType;
	offer_details?: OfferDetails;
	links?: PartnerLinks;
	has_churned: boolean;
	b2b_account_id?: string;
	faq_link?: string;
	group_code?: string;
	redemption_url: string;
	msft_teams_tenant_id?: string;
	parent?: ParentPartner;
	additional_languages?: Languages[];
}

export type PartnerPayload = Omit<Partial<Partner>, 'parent'> & {
	parent?: { id: string | null };
};

export interface OfferDetails {
	percent_off?: number | undefined;
	trial_duration?: TrialDuration | undefined;
	terms?: string | undefined;
	duration_type?: StripeCouponDuration | undefined;
	duration_in_months?: number | undefined;
	max_redemptions?: number | undefined;
	promotion_active?: boolean;
}

export interface BannerMessage {
	flash: boolean;
	message: string;
	isError: boolean;
	backgroundColor?: PaletteColor;
	textColor?: PaletteColor;
}

export const SitewideBannerCodes = {
	SSO_CONFIG_INCOMPLETE: 'SSO_CONFIG_INCOMPLETE',
} as const;
export type SidewideBannerCodeType = (typeof SitewideBannerCodes)[keyof typeof SitewideBannerCodes];

export interface SitewideBanner {
	bannerCode: SidewideBannerCodeType | undefined;
	bannerInfo: JSONValue;
}

export type RecaptchaVisible = boolean;

export const SET_RECAPTCHA_VISIBLE = 'SET_RECAPTCHA_VISIBLE';

export interface SetRecaptchaVisibleAction {
	type: typeof SET_RECAPTCHA_VISIBLE;
	payload: RecaptchaVisible;
}

export type SetRecaptchaVisibleTypes = SetRecaptchaVisibleAction;
